import React, { Component } from 'react';
import { AuthenticationService } from '../../Services/AuthenticationService';
import { Slider } from '../../Helpers/Slider';
import { History } from '../../Helpers/History';

export class Admin extends Component {

  //Initialize State
  state = { 
      currentUser : null,
      sliderVisible: false 
  };

  constructor(props) {
    super(props);
    this.handleSliderClick = this.handleSliderClick.bind(this);
}

async componentDidMount() {
  const isTokenValid = await AuthenticationService.validateToken();
  if(!isTokenValid)
    History.push('/'); 
}

handleSliderClick() {
  this.setState(prev => ({ sliderVisible: !prev.sliderVisible }));
}

  render () {
    return (
        <div>
            <h4>Grizzlesoft Administration Page</h4> 
            <hr/>
            <React.Fragment>
              <button type="button" onClick={this.handleSliderClick} className="btn btn-primary">
                {this.state.sliderVisible ? 'Hide Chicken Slider' : 'Show Chicken Slider'}
              </button>
              <br/><br/>
              <Slider visible={this.state.sliderVisible}>
                <center>
                  <h5>Chicken Slider</h5>
                  <img alt="" src={"images/ChickenSlider.png"} style={{height:'50%', width:'50%'}} />
                  <br />
                  <span>Very high in cholesterol, but delicious</span>
                </center>
              </Slider>
            </React.Fragment>   
        </div>
    );
  }
}
