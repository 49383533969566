import React, { Component } from 'react';

export class Banner extends Component {

    state = { environment: "Production", version: 'X.X.X.X' };

    componentDidMount() {
        this.getEnvironment();
        this.getVersion();
    }

    async getEnvironment() {
        var apiUrl = `/api/GetEnvironment`;
        const response = await fetch(apiUrl);
        if(response.ok)
        {
            var msg = await response.text();
            this.setState({environment : msg});
        }
    }

    async getVersion() {
        var apiUrl = `/api/GetVersion`;
        const response = await fetch(apiUrl);
        if (response.ok) {
            var msg = await response.text();
            this.setState({ version: msg });
        }
    }

    render() {

        let content;
        switch (this.state.environment) {
            case "Development" :   
                content = <div style={{ height: '25px', width: '100%', padding: 0, margin: 0, backgroundImage: "url(images/dev.png)" }}> {this.state.version}</div>;
                break;
            case "Staging" :
                content = <div style={{ height: '25px', width: '100%', padding: 0, margin: 0, backgroundImage: "url(images/staging.png)" }}> {this.state.version}</div>;
                break;
            default:
                content = <div></div> 
                break;
        }

      return (<React.Fragment>{content}</React.Fragment>);
    }
  }