import React, { Component } from 'react';
import { Post }  from '../Pog/Post';
import { SearchResults } from './SearchResults';
import { callApi } from '../../Services/Api';
import { Timeout } from '../../Helpers/Timer';
import { History } from '../../Helpers/History';
import { AuthenticationService } from '../../Services/AuthenticationService';
import { Label, Icon, Segment, Sidebar, Divider, Checkbox, Container, Input, Grid, Button, Loader, Dropdown, Tab } from 'semantic-ui-react'
import { CustomLoader } from '../../Helpers/CustomLoader';
export class PogMain extends Component {


    //Initialize State
    state = { 
        currentPostId : null, 
        sliderVisible: true, 
        actionType: null,
        wasSearched : false,
        searchPhrase : null,
        searchNumber : null,
        isCategorySearch : false,
        isTagSearch: false,
        partialSearch: false,
        searchedPosts: [],
        selectedCategory: "",
        selectedTag: "",
        categories: [],
        tags: [],
    };

    //Input fields
    inputPostNumber = React.createRef();
    inputSearchPhrase = React.createRef();

    constructor(props) {
        super(props);
        this.toggleSearchBar = this.toggleSearchBar.bind(this);
        this.setSearchBarVisibility = this.setSearchBarVisibility.bind(this);
        this.loadSearchablePost = this.loadSearchablePost.bind(this);
    }

    async componentDidMount() {
        //Check for invalid/expired token
        const isTokenValid = await AuthenticationService.validateToken();
        if (!isTokenValid)
            History.push('/');

        await this.GetCategories();
        await this.GetTags();

        //If a postId was passed in as a parameter, just load that post
        const postId = this.props.match.params.postId;
        if(postId !== null && postId !== undefined)
        {
            this.setState({currentPostId : postId, actionType: "POST"});
        }
    }

    onPostNumberChange()
    {
        this.inputPostNumber.current.inputRef.current.value = this.inputPostNumber.current.inputRef.current.value.replace(/\D/, '');
        this.setState({searchNumber: this.inputPostNumber.current.inputRef.current.value}); 
    }

    toggleSearchBar() {
        this.setState(prev => ({ sliderVisible: !prev.sliderVisible }));
      }

    togglePartialSearch(){
        this.setState(prev => ({ partialSearch: !prev.partialSearch }));
    }

    setSearchBarVisibility(value){
        this.setState({ sliderVisible: value });
    }

    loadSearchablePost(postId, searchString, includePartial, categorySearch, tagSearch){
        this.setState({ 
            actionType: "POST",
            currentPostId : postId,
            wasSearched : true,
            searchPhrase : searchString,
            partialSearch : includePartial,
            isCategorySearch : categorySearch,
            isTagSearch: tagSearch
        });
    }

    handleCategoryChange = (event, data) => {
        this.setState({selectedCategory: data.options.filter(x => x.value === data.value)[0]});
    }

    handleTagChange = (event, data) => {
        this.setState({selectedTag: data.options.filter(x => x.value === data.value)[0]});
    }

    LoadPost(){
        this.setState({ 
                actionType: "POST",
                currentPostId : this.inputPostNumber.current.inputRef.current.value,
                wasSearched : false
        });
    }

    async SearchForPosts(){
        this.setState({actionType : "SEARCHING", sliderVisible : false});
        //Getting direct value from semantic-ui react input box
        var inputSearchString = this.inputSearchPhrase.current.inputRef.current.value;
        await Timeout(2000);       
        var apiUrl = `/api/GetPostsBySearchPhrase?search=${inputSearchString}&includePartial=${this.state.partialSearch}`;
        const posts = await callApi(apiUrl, "GET");
        if(posts.ok)
            {
                const response = await posts.json();
                this.setState({
                    searchedPosts: response, 
                    actionType: "SEARCH_RESULTS", 
                    searchPhrase: inputSearchString,
                    searchTitle: inputSearchString,
                    wasSearched: true,
                    isCategorySearch : false,
                    isTagSearch: false, 
                });
            }
            else{
                const error = await posts.json();
                this.setState({actionType : "ERROR", sliderVisible : false, errorMessage : error});
            }
    }

    async SearchForPostsByCategory()
    {
        this.setState({actionType : "SEARCHING", sliderVisible : false});
        var categoryId = this.state.selectedCategory.value;
        var categoryName = this.state.selectedCategory.text;
        await Timeout(2000);       
        var apiUrl = `/api/GetPostsByCategory?categoryId=${categoryId}`;
        const posts = await callApi(apiUrl, "GET");
        if(posts.ok)
            {
                const response = await posts.json();
                this.setState({
                    searchedPosts: response, 
                    actionType: "SEARCH_RESULTS",
                    searchTitle: "Category - " + categoryName,
                    wasSearched: true,
                    isCategorySearch: true
                });
            }
            else{
                const error = await posts.json();
                this.setState({actionType : "ERROR", sliderVisible : false, errorMessage : error});
            }
    }

    async SearchForPostsByTag()
    {
        this.setState({actionType : "SEARCHING", sliderVisible : false});
        var tagId = this.state.selectedTag.value;
        var tagName = this.state.selectedTag.text;
        await Timeout(2000);       
        var apiUrl = `/api/GetPostsByTag?tagId=${tagId}`;
        const posts = await callApi(apiUrl, "GET");
        if(posts.ok)
            {
                const response = await posts.json();
                this.setState({
                    searchedPosts: response, 
                    actionType: "SEARCH_RESULTS",
                    searchTitle: "Tag - " + tagName,
                    wasSearched: true,
                    isTagSearch: true
                });
            }
            else{
                const error = await posts.json();
                this.setState({actionType : "ERROR", sliderVisible : false, errorMessage : error});
            }
    }

    async GetCategories()
    {
        var apiUrl = `/api/GetCategories`;
        const response = await callApi(apiUrl, "GET");
        if(response.ok)
        {
            var categories = await response.json();
            categories = categories.map((category) => {
                return {
                  key: category.categoryId,
                  value: category.categoryId,
                  text: category.categoryName,
                }
              })
            this.setState({categories : categories});
        }
        else{
            const error = await response.json();
            this.setState({actionType : "ERROR", sliderVisible : false, errorMessage : error});
        }
    }

    async GetTags()
    {
        var apiUrl = `/api/GetTags`;
        const response = await callApi(apiUrl, "GET");
        if(response.ok)
        {
            var tags = await response.json();
            tags = tags.map((tag) => {
                return {
                  key: tag.tagId,
                  value: tag.tagId,
                  text: tag.tagName,
                }
              })
            this.setState({tags : tags});
        }
        else{
            const error = await response.json();
            this.setState({actionType : "ERROR", sliderVisible : false, errorMessage : error});
        }
    }

    async ShowSearchResults(){
        this.setState({actionType : "SEARCH_RESULTS", sliderVisible : false});
    }   

    render()
    {   
        let content;

        const panes = [
            { menuItem: 'Search', render: () => 
            <Tab.Pane style={{backgroundColor:"skyblue", border: '0px', padding:0, margin:0}} >           
                <Divider horizontal><span>Search by phrase</span></Divider>    
                <Grid>
                    <Grid.Column style={{textAlign:"center", paddingTop:"5px"}}>
                            <Input ref={this.inputSearchPhrase} icon='search' placeholder='Enter search phrase...' defaultValue={this.state.searchPhrase} style={{width:"99%"}} />   
                    </Grid.Column> 
                </Grid>  
                <Grid>
                    <Grid.Column width={10} style={{textAlign:"center"}}>
                        <span style={{color:"black"}}><b>Include partial matches</b></span>
                    </Grid.Column>
                    <Grid.Column width={6} style={{textAlign:"center"}}>
                            <Checkbox toggle id="chkPartialMatches" onChange={() => this.togglePartialSearch()} checked={this.state.partialSearch} />   
                    </Grid.Column>
                </Grid>
                <Button content='Search By Phrase' primary onClick={() => this.SearchForPosts()} />
                <Divider horizontal><span>Search by number</span></Divider> 
                <Grid>
                    <Grid.Column style={{textAlign:"center", paddingTop:"5px"}}>
                            <Input ref={this.inputPostNumber} icon='hashtag' maxLength="4" placeholder='Enter post number...' onChange={() => this.onPostNumberChange()} defaultValue={this.state.searchNumber} style={{width:"85%"}} />   
                    </Grid.Column>
                </Grid>    
                <br />
                <Button content='Load Post' primary  onClick={() => this.LoadPost()} />    
                <Divider horizontal><span>Search Results</span></Divider>  
                {this.state.searchedPosts.length > 0
                ?   <Label as='a' style={{color:"black"}} onClick={() => this.ShowSearchResults()}><Icon name='list ul' />{this.state.searchedPosts.length} Search Results For [{this.state.searchTitle}] </Label>
                :  <span></span>}   
                <br /><br />   
            </Tab.Pane> },
            { menuItem: 'Category', render: () => <Tab.Pane style={{backgroundColor:"skyblue", border: '0px', padding:0, margin:0}} >
                   <Divider horizontal><span>Search by category</span></Divider>  
                <Grid>
                    <Grid.Column style={{textAlign:"center", paddingTop:"5px"}}>
                    <Dropdown 
                    onChange={this.handleCategoryChange}
                    placeholder="Select Category"
                    fluid
                    search
                    selection 
                    key="ddlCategories"
                    defaultSearchQuery={this.state.selectedCategory.text}
                    options={this.state.categories}/>
                    </Grid.Column>
                </Grid>    
                <br/>
                <Button content='Search By Category' primary onClick={() => this.SearchForPostsByCategory()} />
                <Divider horizontal><span>Search Results</span></Divider>  
                {this.state.searchedPosts.length > 0
                    ? <Label as='a' style={{ color: "black" }} onClick={() => this.ShowSearchResults()}><Icon name='list ul' />{this.state.searchedPosts.length} Search Results For [{this.state.searchTitle}] </Label>
                :  <span></span>}   
                <br /><br />   
            </Tab.Pane> },
             { menuItem: 'Tag', render: () => <Tab.Pane style={{backgroundColor:"skyblue", border: '0px', padding:0, margin:0}} >
               <Divider horizontal><span>Search by tag</span></Divider>  
                <Grid>
                    <Grid.Column style={{textAlign:"center", paddingTop:"5px"}}>
                    <Dropdown 
                    onChange={this.handleTagChange}
                    placeholder="Select Tag"
                    fluid
                    search
                    selection 
                    key="ddlTags"
                    defaultSearchQuery={this.state.selectedTag.text}
                    options={this.state.tags}/>
                    </Grid.Column>
                </Grid>    
                <br/>
                <Button content='Search By Tag' primary onClick={() => this.SearchForPostsByTag()} />
                <Divider horizontal><span>Search Results</span></Divider>  
                {this.state.searchedPosts.length > 0
                     ? <Label as='a' style={{ color: "black" }} onClick={() => this.ShowSearchResults()}><Icon name='list ul' />{this.state.searchedPosts.length} Search Results For [{this.state.searchTitle}] </Label>
                :  <span></span>}   
                <br /><br />   
         </Tab.Pane> },
          ]
          
        switch (this.state.actionType) {
            case "SEARCHING" :   
                content =   <div><Loader active>Searching</Loader></div>;
                break;
            case "POST" :
                content =   <Post 
                                postId={this.state.currentPostId} 
                                key={this.state.currentPostId} 
                                setSearchBarVisibility={this.setSearchBarVisibility}
                                wasSearched={this.state.wasSearched}
                                searchString={this.state.searchPhrase}
                                includePartial={this.state.partialSearch}
                                isCategorySearch={this.state.isCategorySearch}
                                isTagSearch={this.state.isTagSearch}>                              
                            </Post>;
                break;
            case "SEARCH_RESULTS" :
                content = <SearchResults 
                                posts={this.state.searchedPosts} 
                                loadSearchablePost={this.loadSearchablePost} 
                                searchPhrase={this.state.searchPhrase}
                                includePartial={this.state.partialSearch}
                                isCategorySearch={this.state.isCategorySearch}
                                searchTitle={this.state.searchTitle}
                                isTagSearch={this.state.isTagSearch}>   
                            </SearchResults>;
                break;
            case "ERROR" :
                content = 
                    <CustomLoader Type={"Error"} Message={this.state.errorMessage}></CustomLoader>;
                break;
            default:
                content = <div><b>Search for posts from the search menu</b></div>;  
                break;
        }

        return (
            <React.Fragment>
                    <button type="button" className="btn btn-success" 
                        onClick={this.toggleSearchBar}> 
                        {this.state.sliderVisible 
                            ? <React.Fragment><Icon name='search minus' /> Hide Search <Icon name='arrow left' /> </React.Fragment> 
                            : <React.Fragment><Icon name='search plus' /> Show Search <Icon name='arrow right' /></React.Fragment>} 
                    </button>
                    <Loader>Searching</Loader>
                    <br/><br/>
                    <Sidebar.Pushable as={Segment}>
                        <Sidebar
                            as={Segment}
                            style={{backgroundColor:"skyblue", padding:0}}
                            animation='slide along'
                            inverted
                            vertical
                            visible={this.state.sliderVisible}
                            width='wide'>
                        <Container fluid style={{width:"95%", fontSize:13}}>
                            <Tab panes={panes} style={{border: '0px', padding:0, margin:0}} ></Tab>    
                        </Container>            
                        </Sidebar>
                        <Sidebar.Pusher>
                            <Segment basic style={{minHeight:500}}>
                                {content}
                            </Segment>   
                        </Sidebar.Pusher>
                    </Sidebar.Pushable>       
            </React.Fragment>
        );        
  }
}
