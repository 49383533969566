import React, { Component } from 'react';
import { Image, Button, Modal } from 'semantic-ui-react';
import { History } from '../../Helpers/History';

export class UnitTile extends Component {

    state = {
        open: false,
        imageTilePath: "url('" + "images/selection/Tile" + this.props.tileNumber + "Scaled.png" + "'",
        imageUnitPath: 'images/portraits/' + this.props.unit + '.png'
    };

    close = () => this.setState({ open: false })

    submitHero = () => {
        if (this.props.unit == "Gladys Foster")
            History.push('/Gladys');
        else
            this.close();
    }

    unitClicked = (event) => {
        if (!this.props.unit.includes("Question")) {
            this.setState({ open: true })
        }
    }

    render() {

        var cursorStyle = "initial";
        if (!this.props.unit.includes("Question")) {
            cursorStyle = "pointer";
        }

        return (
            <React.Fragment>
                <div onClick={this.unitClicked} style={{
                    height: this.props.height,
                    width: this.props.width,
                    backgroundImage: this.state.imageTilePath,
                    backgroundPosition: this.props.backgroundPosition,
                    backgroundRepeat: this.props.backgroundRepeat,
                    cursor: cursorStyle,
                    padding: this.props.padding
                }}>
                    <Image src={this.state.imageUnitPath} style={{ width: '80px' }} />
                </div>
                <Modal dimmer
                    closeOnEscape={true}
                    closeOnDimmerClick={false}
                    open={this.state.open}
                    onClose={this.close}>
                    <Modal.Header>{this.props.unit}</Modal.Header>
                    <Modal.Content>
                        <p>Accept this hero?</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.close} negative>
                            No
                        </Button>
                        <Button
                            onClick={this.submitHero}
                            positive
                            labelPosition='right'
                            icon='checkmark'
                            content='Yes'
                        />
                    </Modal.Actions>
                </Modal>
            </React.Fragment>
        );
    }
}

UnitTile.defaultProps = {
  height : "100px",
  width : "100px",
  tileNumber : '1',
  unit: 'Wesley',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  padding: '10px',
}
