import React, { Component } from 'react';
import { History } from '../../Helpers/History';
import { Segment, Loader, Dimmer, Label, Icon } from 'semantic-ui-react'

export class Activate extends Component {

  //Initialize State
  state = {
    isActivating: true,
    hasError: false
  };

  async componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    var key = params.get('key')
    if (key === null) {
      //Missing Key
      History.push('/');
    }
    else {
      var apiUrl = `/api/activate`;
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'text/plain' },
        body: key
      };
      const result = await fetch(apiUrl, requestOptions);
      if (result.ok) {
        this.setState({ isActivating: false });
      }
      else {
        this.setState({ hasError: true });
      }
    }
  }

  render() {

    if (this.state.isActivating) {
      return (
        <Segment placeholder>
          <Segment.Inline>
            {
              <React.Fragment>
                <Dimmer active inverted>
                  <Loader>Activating Account</Loader>
                </Dimmer>
              </React.Fragment>
            }
          </Segment.Inline>
        </Segment>
      );
    }

    if (this.state.hasError) {
      return (
        <Segment placeholder>
          <Segment.Inline>
            {
              <React.Fragment>
               <span>Error activating account</span>
              </React.Fragment>
            }
          </Segment.Inline>
        </Segment>
      );
    }

    return (
      <Segment placeholder>
      <Segment.Inline>
        {
          <React.Fragment>
           <span>Account activated successfully</span>
           <br />
           <Label as='a' href='/login'>
            <Icon name='user circle' /> Click here to login
           </Label>
          </React.Fragment>
        }
      </Segment.Inline>
    </Segment>
    );
  }
}
