import React, { Component } from 'react';
import '../css/slider.css';

export class Slider extends Component {

    render() {
      return (
        <div id="Slider" className={this.props.visible ? 'slideIn' : 'slideOut'}>
            {this.props.children}
        </div>
      );
    }
  }