import React, { Component } from 'react';
import { AuthenticationService } from '../../Services/AuthenticationService';
import { Timeout } from '../../Helpers/Timer';
import { History } from '../../Helpers/History';
import { Header, Icon, Button, Grid, Input } from 'semantic-ui-react';

export class Login extends Component {

  inputUsernameRef = React.createRef();
  inputPasswordRef = React.createRef();

  //Initialize State
  state = { 
      isLoggingIn: false, 
      loginError: null, 
  };

  //Allow enter key to submit form
  handleKeyPress = (event) => {
      if (event.key === 'Enter') {
          this.Login();
      }
  }

  async componentDidMount()
  {
    const currentUser = AuthenticationService.currentUserValue;
      if (currentUser !== null) { 
        History.push('/'); 
      }
  }

  async Login()
  {
    this.setState({ isLoggingIn: true });
    await Timeout(1500);
    var response = await AuthenticationService.login(this.inputUsernameRef.current.inputRef.current.value, this.inputPasswordRef.current.inputRef.current.value);
    if(response.message === "Login Success")
    {
      History.push('/'); 
    }
    else
    {
      this.setState({ isLoggingIn: false, loginError: response.message});
    }
  }

  render () {

    const FullPageOuter = 
    {
    position: 'relative',
    top: 0,
    left: 0,
    bottom: 0, 
    right: 0,
    display: 'flex', 
    alignItems: 'center', 
    overflow: 'hidden'
    };

    const FullPageInner = 
    {
      margin: 'auto',
      maxHeight: '100%',
      height : '300px',
      width: '100%',
      padding: '25px',
      backgroundImage: `url('images/selection/LoginDrop.png')`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    }

    return (
      <React.Fragment>
      <div style={FullPageOuter}>
        <div style={FullPageInner}>
            <Grid columns={2} stackable textAlign='center'>  
              <Grid.Row verticalAlign='middle'>
                <Grid.Column>
                    <Header icon>
                      <Icon inverted color='grey' name='user circle' size='mini' />
                    </Header>
                    <br />
                    <Input ref={this.inputUsernameRef} disabled={this.state.isLoggingIn} iconPosition='left' icon='user' placeholder='Username' onKeyPress={this.handleKeyPress}/> 
                    <br />
                    <br />
                    <Input ref={this.inputPasswordRef} disabled={this.state.isLoggingIn} iconPosition='left' icon='user secret' type="password" placeholder='Password' onKeyPress={this.handleKeyPress} /> 
                    <br />
                    <br />
                    <Button secondary onClick={() => this.Login()} disabled={this.state.isLoggingIn}>
                      {this.state.isLoggingIn ? <span> Logging in <Icon loading name='spinner' /></span> : <div>Login</div>}
                    </Button>
                    <br />
                    {this.state.loginError ? <span style={{color: "white"}}>{this.state.loginError}</span> : <div></div>}         
                  </Grid.Column>    
              </Grid.Row>
            </Grid>         
        </div>
    </div>
    </React.Fragment>);
  }
}
