import { AuthenticationService } from './AuthenticationService';
import { History } from '../Helpers/History';

export async function callApi(url, methodType, data) {

    //Validate token before every api call
    const isTokenValid = await AuthenticationService.validateToken();
    if(isTokenValid)
    {
        const currentUser = AuthenticationService.currentUserValue;
        var token = `Bearer ${currentUser.token}`;

        const response = await fetch(url,
            {
                method: methodType,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify(data)
            });
        return response;
    }
    else
    {
        //Invalid token, redirect user
        History.push('/'); 
        const error = "Invalid token";
        return Promise.reject(error);
    }
}
