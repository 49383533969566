import { BehaviorSubject } from 'rxjs';
import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css';

//Check local storage for currentUser if getting token issue
const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const AuthenticationService = {
    login,
    logout,
    validateToken,
    isLoggedIn,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};

async function handleErrors(response)
{
    if(!response.ok)
    {
        var errorMessage = await response.text();
        throw new Error(errorMessage);
    }
    return response;
}

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(`api/Login`, requestOptions)
        .then(handleErrors)
        .then(response => response.json())
        .then(response => {
            localStorage.setItem('currentUser', JSON.stringify(response));
            currentUserSubject.next(response);
            return response;
        })
        .catch(error => {return error});
}

function isLoggedIn()
{
    if (currentUserSubject && currentUserSubject.value && currentUserSubject.value.token)
        return true;
    else
        return false;
}

function logout() {
    //Remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);

    // Show logout notification
    store.addNotification({
        title: 'Logout',
        width: 300,
        message: 'You have successfully logged out',
        type: 'success',                         // 'default', 'success', 'info', 'warning'
        container: 'top-right',                  
        animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
        animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
        dismiss: {
          duration: 3000 
        }
      });
}

async function validateToken()
{
    if (isLoggedIn()) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: currentUserSubject.value.token
        };
         const result = await fetch(`api/ValidateToken`, requestOptions);

         if(result.ok)
         {
             return true;
         }
         else
         {
            //Remove user from local storage because user timed out
            localStorage.removeItem('currentUser');
            currentUserSubject.next(null);

            // Show timeout notification
            store.addNotification({
            title: 'Timeout',
            width: 300,
            message: 'Session timed out, please login again',
            type: 'warning',
            container: 'top-right',                  
            animationIn: ["animated", "fadeIn"], 
            animationOut: ["animated", "fadeOut"], 
            dismiss: {
            duration: 3000 
            }
        });
            return false;
         }
    }
    else
    {
        return false;
    }  
}