import React, { Component } from 'react';
import { Container } from 'reactstrap';
import ReactNotifications from 'react-notifications-component';
import { NavMenu } from './NavMenu';
import { Banner } from './Banner';

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
      <div>
        <ReactNotifications />
        <Banner />
        <NavMenu/>
        <Container>
          {this.props.children}
        </Container>
      </div>
    );
  }
}
