import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import { Icon } from 'semantic-ui-react'

export class CustomLoader extends Component {

  render() {

    const FullPageOuter = 
    {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0, 
    right: 0,
    display: 'flex', 
    alignItems: 'center', 
    overflow: 'auto'
    };

    const FullPageInner = 
    {
      margin: 'auto',
      maxHeight: '100%'
    }

    const MessageStyle = 
    {
      width: '60',
      alignContent: 'center',
      textAlign: 'center'
    }

    if(this.props.Type === "Loader")
    {
      return (
        <React.Fragment>
          <div style={FullPageOuter}>
            <div style={FullPageInner}>
              <Loader type={this.props.LoadingType} color={this.props.LoadingColor} height={60} width={60} />
              <div style={MessageStyle}><b style={{color:this.props.TextColor}}>{this.props.Message}</b></div>
            </div>
        </div>
        </React.Fragment>)
    }

    if(this.props.Type === "Error")
    {
      return (
        <React.Fragment>
          <div style={FullPageOuter}>
            <div style={FullPageInner}>
              <div style={{MessageStyle, color:"red"}}><Icon name='warning circle' /><b style={{color:this.props.TextColor}}>{this.props.Message}</b></div>
            </div>
        </div>
        </React.Fragment>)
      }
  }
}

CustomLoader.defaultProps = {
  Type : "Loader",
  LoadingType : "Bars",
  LoadingColor: "#00BFFF",
  TextColor : "#000000",
  Message: ""
}

//Documentation: https://www.npmjs.com/package/react-loader-spinner
//https://mhnpd.github.io/react-loader-spinner/?path=/story/loader--audio