import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthenticationService } from '../Services/AuthenticationService';

export const PrivateRoute = ({ component: Component, requiredRole, ...rest }) => (
    <Route {...rest} render={props => {

            //Get current user from authentication service
            const currentUser = AuthenticationService.currentUserValue;

            //User not logged in, redirect to login page
            if (!currentUser) {
                return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            }

            //Route is restricted by role, redirect to home page
            if (currentUser.roles.indexOf(requiredRole) === -1) {
                return <Redirect to={{ pathname: '/'}} />
            }

            //User is authorized to view this componenet
            return <Component {...props} />          
    }} />
)