import React, { Component } from 'react';
import { Router, Route } from 'react-router-dom';
import { Layout } from './components/Layout/Layout';
import { Home } from './components/Pages/Home';
import { Login } from './components/Account/Login';
import { Gladys } from './components/Pages/Gladys';
import { Activate } from './components/Account/Activate';
import { FetchData } from './components/Pages/FetchData';
import { Counter } from './components/Pages/Counter';
import { PogMain } from './components/Pog/PogMain';
import { Admin} from './components/Admin/Admin';
import { History } from './Helpers/History';
import { PrivateRoute } from './Helpers/PrivateRoute';
import { CharacterSelection } from './components/Character/CharacterSelection';

import 'semantic-ui-css/semantic.min.css'
import './css/custom.css';

export default class App extends Component {
  static displayName = App.name;

  //Initialize State
  render () {
    return (
      <Router history={History}>
        <Layout>
          <Route exact path='/' component={Home} />
          <Route path='/Login' component={Login} />
          <Route path='/Activate' component={Activate} />
          <Route path='/counter' component={Counter} />
          <Route path='/fetch-data' component={FetchData} />
          <Route path='/Character' component={CharacterSelection} />
          <Route path='/Gladys' component={Gladys} />
          <PrivateRoute path='/pog/:postId?' requiredRole={"POG"} component={PogMain} />
          <PrivateRoute path='/admin' requiredRole={"Administrator"} component={Admin} />
        </Layout>
      </Router>
    );
  }
}
