import React, { Component } from 'react';
import { Header, Icon, Segment } from 'semantic-ui-react'
import { AuthenticationService } from '../../Services/AuthenticationService';

export class Home extends Component {

  //Initialize State
  state = {
    currentUser: AuthenticationService.currentUserValue,
    isMounted: false
  };

  render() {
    return (
      <Segment placeholder>
        <Header icon>
          <Icon name='home' />
          Home
        </Header>
        <Segment.Inline>
          {
            this.state.currentUser != null
              ? <span>Logged in as: {this.state.currentUser.username}</span>
              : <span>Not Logged In</span>
          }
        </Segment.Inline>
      </Segment>
    );
  }
}
