import React, { Component } from 'react';
import { Label } from 'semantic-ui-react'

export class SearchResults extends Component {

    state = { 
        posts : this.props.posts,
        searchPhrase: this.props.searchPhrase,
        includePartial: this.props.includePartial,
        isCategorySearch: this.props.isCategorySearch,
        isTagSearch: this.props.isTagSearch,
        searchTitle: this.props.searchTitle
    };

    loadSearchedPost(postId, searchString, includePartial, isCategorySearch, isTagSearch)
    {
        //Call the parent method to load post
        this.props.loadSearchablePost(postId, searchString, includePartial, isCategorySearch, isTagSearch);
    }

    render() {
        var showPartialSearchText = this.state.includePartial && !this.state.isCategorySearch && !this.state.isTagSearch ? " including partial matches": "";
        return (         
            <React.Fragment>
              <div>
                <table className='table' aria-labelledby="tabelLabel">
                    <thead>
                    <tr style={{backgroundColor:"skyblue"}}>
                        <th>{this.state.posts.length} Search Results for [{this.state.searchTitle}] {showPartialSearchText}
                        </th>
                        <th>
                            Source
                        </th>
                        <th>
                            Date
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.posts.map(post =>
                        <tr key={post.postId}>
                            <td><Label as='a' onClick={() => 
                                this.loadSearchedPost(post.postId,
                                this.state.searchPhrase, 
                                this.state.includePartial,
                                this.state.isCategorySearch,
                                this.state.isTagSearch)}>{post.postTitle}</Label></td>
                            <td>{post.source}</td>
                            <td>{post.postDate}</td>
                        </tr>
                    )}    
                    </tbody>
                </table>
          </div>
            </React.Fragment>
        );
    }
}
