import React, { Component } from 'react';
import { Grid, Button, Modal } from 'semantic-ui-react';
import { UnitTile } from '../Character/UnitTile';
import { CustomLoader } from '../../Helpers/CustomLoader';

export class CharacterSelection extends Component {

  //Initialize State
  state = { 
    characters : [],
    loading: false,
    width: window.innerWidth,
  };

  async componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
    this.LoadCharacters();
}

  // Remove the listener when the componenent is unmounted
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }
  
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  async LoadCharacters()
  {
        this.setState({ loading: true }); 
        var apiUrl = `/api/GetCharacters`;
        const result = await fetch(apiUrl,
          {
              method: 'GET',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
          });

        if(result.ok)
        {
            const response = await result.json();
            this.setState({ characters: response, loading : false });
        }
        else if(!result.ok) {
            const error = await result.json();
            this.setState({ hasError: true, loading: false, errorMessage: error });
        }
  }

  render() {

    let columnSize = Math.round(this.state.width / 100) - 1;
    if(columnSize < 3) columnSize = 3;
    else if(columnSize > 8) columnSize = 8;
    
    if(this.state.loading)
    {
      return (<CustomLoader Type={"Loader"} Message={"Loading"} LoadingType={"ThreeDots"}></CustomLoader>)
    }

      return (
        <React.Fragment>
         <div>
      <span>{columnSize}</span>
        <Grid columns={columnSize}>     
          <Grid.Row>
            {this.state.characters.map((char, index) =>
              <Grid.Column key={index} className="customColumn">
              <UnitTile tileNumber={char.tileNumber} unit={char.characterName}></UnitTile>
            </Grid.Column>)}  
          </Grid.Row>             
        </Grid>
        </div>
        </React.Fragment>)
  }
} 