import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react'
import { AuthenticationService } from '../../Services/AuthenticationService';
import { History } from '../../Helpers/History';

import '../../css/navmenu.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  //Initialize State
  state = { 
      collapsed: false, 
      currentUser: null,
      isMounted: false 
  };

  constructor (props) {
    super(props);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state.currentUser = null;
  }

  async componentDidMount()
  {
   if(!this.state.isMounted)
   {
    //Subscribe to an observable so that the navigation component will re-render when the user changes
    AuthenticationService.currentUser.subscribe(x => this.setState({currentUser: x}));
   }
   else{
      this.setState({isMounted: true});
   }
  }

  componentWillUnmount() {
    this.setState({isMounted: false});
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  async Logout()
  {
    AuthenticationService.logout();
    History.push('/'); 
  }

  render () {
    return (
      <header style={{backgroundColor:"black"}}>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
          <Container>
            <NavbarBrand tag={Link} to="/" className="navBarDarkTheme">
              <img src="images/GrizzlesoftBear.png" alt="Logo" height="48px" width="48px"/>
              <span style={{paddingLeft:15, verticalAlign:"middle", fontFamily:"inherit"}} ><b>Grizzlesoft</b></span>
              </NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2 navBarDarkToggleTheme" />        
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                <NavItem>
                  <NavLink tag={Link} className="navBarDarkTheme" to="/">Home</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="navBarDarkTheme" to="/character">Character</NavLink>
                </NavItem>
                <NavItem>
                    {this.state.currentUser && this.state.currentUser.roles.indexOf('POG') !== -1 
                    ? <NavLink tag={Link} className="navBarDarkTheme" to="/pog">POG</NavLink>
                    : <span></span>}     
                </NavItem>
                <NavItem>
                    {this.state.currentUser && this.state.currentUser.roles.indexOf('Administrator') !== -1 
                    ? <NavLink tag={Link} className="navBarDarkTheme" to="/admin">Administration</NavLink>
                    : <span></span>}     
                </NavItem>
                <NavItem>
                    {this.state.currentUser
                    ?  <button className="navBarDarkTheme nav-link navBarLogoutButton" onClick={() => this.Logout()}>Logout <Icon name='sign-out alternate' /></button> 
                    :  <NavLink tag={Link} className="navBarDarkTheme" to="/login">Login <Icon name='sign-in alternate' /></NavLink>}      
                </NavItem>
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}
