import React, { Component } from 'react';
import { callApi } from '../../Services/Api';
import { CustomLoader } from '../../Helpers/CustomLoader';
import { Timeout } from '../../Helpers/Timer';

export class Post extends Component {

    //Initialize State
    state = { 
        loading: false, 
        hasError: false, 
        isRendered: false, 
        selectedPostId: this.props.postId,
        wasSearched: this.props.wasSearched,
        searchString: this.props.searchString,
        includePartial: this.props.includePartial,
        isCategorySearch: this.props.isCategorySearch,
        isTagSearch: this.props.isTagSearch
    };

    async LoadPost()
    {
        if(this.state.selectedPostId !== null && this.state.selectedPostId !== "")
        {
            //Call the parent method to close out search
            this.props.setSearchBarVisibility(false); 
            this.setState({ loading: true }); 

            var apiUrl = `/api/GetPost?postId=${this.state.selectedPostId}`;
            if(this.state.wasSearched && !this.state.isCategorySearch && !this.state.isTagSearch)
             apiUrl += `&wasSearched=${this.state.wasSearched}&searchPhrase=${this.state.searchString}&includePartial=${this.state.includePartial}`;

            const post = await callApi(apiUrl, "GET");

            if(post.ok)
            {
                const response = await post.json();
                this.setState({ postContent: response.postContent });
                await Timeout(2000);
                this.setState({ loading: false, isRendered: true }); 
            }
            else if(!post.ok) {
                const error = await post.json();
                this.setState({ hasError: true, loading: false, errorMessage: error });
            }
        }
        else {
            //No post is selected
            this.props.setSearchBarVisibility(true); 
            this.setState({ hasError: false, loading: false }); 
        }      
    }

    async componentDidMount() {
        this.LoadPost();
    }

    createDynamicHTML() {
        return {__html: this.state.postContent};
        }

    render()
    {
        if (this.state.hasError)
            return (
                <CustomLoader Type={"Error"} Message={this.state.errorMessage}></CustomLoader>
            );

        if (this.state.loading)
            return (
                <CustomLoader Type={"Loader"} Message={"Loading"} LoadingType={"ThreeDots"}></CustomLoader>
            );

        let spanText = this.state.selectedPostId ? <span></span> : <span><b>Select a post from the search menu</b></span>;
        //Load dynamic markup from post
        return (
          
            <React.Fragment>
                <span>{spanText}</span>
                <div style={{visibility: this.state.isRendered ? 'visible' : 'hidden'}}>
                    <div dangerouslySetInnerHTML={this.createDynamicHTML()} />
                </div>
            </React.Fragment>
        );
    }
}
