import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react'

export class Gladys extends Component {

  render() {
    return (
      <div class="iframe-container">
        <iframe src="/gladysfoster/index.html" allowfullscreen></iframe>
      </div>
    );
  }
}
